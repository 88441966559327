import React from 'react';
import StandardOutboundLink from './standard-outbound-link';

const QuoteStripeAmanda = () => (
  <div className="stripe-hr">
    <q>
      It was so fun! The kids were so engaged and helping each other
      and it really encouraged healthy competition.
    </q>
    <br />
    <br />
    - Amanda, Volunteer at
    {' '}
    <StandardOutboundLink href="https://www.coderdojosaintpaul.org/" className="white-link">
      CoderDojo St Paul
    </StandardOutboundLink>
  </div>
);

export default QuoteStripeAmanda;
