import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import QuoteStripeAmanda from '../components/quote-stripe-amanda';
import NavigationButton from '../components/navigation-button';

const EducatorsPage = () => (
  <Layout>
    <SEO
      title="Educators"
      description="Bring the ultimate competitive computer coding experience to your students!"
      keywords={['teach', 'coding curriculum', 'tournament']}
    />
    <h1>Educators</h1>
    <div className="stripe-hr thin" />
    <div className="two-wide-container">
      <div>
        <div className="pricing-panel">
          <div className="panel-title">
            Run a Tournament
          </div>
          <div className="panel-body">
            <p>Ready to bring competitive coding to your students? Create a tournament.</p>
            <NavigationButton
              path="/tournament/pricing/"
              text="See Pricing"
            />
          </div>
        </div>
      </div>
      <div className="pricing-panel">
        <div className="panel-title">
          Log In
        </div>
        <div className="panel-body">
          <p>Log in to an existing tournament or create a new tournament for your students.</p>
          <NavigationButton
            path="/tournament/home/"
            text="Log In"
          />
        </div>
      </div>
      <div>
        <div className="pricing-panel">
          <div className="panel-title">
            Learn More
          </div>
          <div className="panel-body">
            <p>Learn how to run Code Championship Tournaments for your students.</p>
            <NavigationButton
              path="/tournament/instructions/"
              text="Tournament Guide"
            />
          </div>
        </div>
      </div>
      <div>
        <div className="pricing-panel">
          <div className="panel-title">
            Go Deeper
          </div>
          <div className="panel-body">
            <p>Use Code Championship as your class&apos;s introduction to computer science.</p>
            <NavigationButton
              path="/curriculum/"
              text="Full Curriculum"
            />
          </div>
        </div>
      </div>
    </div>
    <QuoteStripeAmanda />
  </Layout>
);

export default EducatorsPage;
