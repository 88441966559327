import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const NavigationButton = ({ path, text, small }) => (
  <Link to={path} activeClassName="selected">
    <button
      type="button"
      className={`stripe-button secondary${small ? ' small-nav-button' : ''}`}
    >
      {text}
    </button>
  </Link>
);

NavigationButton.propTypes = {
  path: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  small: PropTypes.bool,
};

NavigationButton.defaultProps = {
  small: false,
};

export default NavigationButton;
